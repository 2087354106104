import axios from '@/common/axios'
import qs from 'qs'

export function login (data) {
  return axios({
    method: 'post',
    url: '/login',
    data: qs.stringify(data)
  })
}
